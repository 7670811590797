import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../apis/authentication.service";

@Injectable({providedIn: 'root'})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private auth: AuthenticationService) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let authReq: HttpRequest<unknown> = request;

        if (!(request.url.includes('/v4/marketplace/auth')
            || request.url.includes('/v4/marketplace/auth-token')
            || request.url.includes('/v4/marketplace/forgot-password')
            || request.url.includes('/v4/marketplace/validate-password-reset')
            || request.url.includes('/v4/marketplace/change-user-password')
        )) {
            const _authToken = this.auth.getCurrentUser?.token;
            authReq = request.clone({
                setHeaders: {
                    'X-Auth-Token': _authToken,
                    // Reference: https://developer.mozilla.org/en-US/docs/Web/HTTP/Permissions_Policy
                    'Permissions-Policy': `geolocation=*,sync-xhr=*,encrypted-media=*`,
                    'X-Frame-Options': 'SAMEORIGIN',
                    'X-Content-Type-Options': 'nosniff',
                    'X-Xss-Protection': '1; mode=block',
                    // Will not allow any information to be sent when a scheme downgrade happens (when user is navigating from HTTPS to HTTP).
                    'Referrer-Policy': 'strict-origin-when-cross-origin',
                    'Content-Security-Policy': `script-src 'self'`
                }
            });
        }

        return next.handle(authReq);
    }

}
