import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {Observable, take, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {UserV1Model} from "../../_model/user.model";

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    currentUserDetails: UserV1Model | undefined;

    constructor(
        private http: HttpClient,
        private authSvc: AuthenticationService
    ) {
    }

    /**
     * This method got initiated in the LayoutComponent
     */
    public initUserDetails(): Observable<UserV1Model> {
        const url = `${environment.apiBaseUrl}/v1/users/${this.authSvc.getCurrentUser?.userId}`;
        return this.http.get<UserV1Model>(url)
            .pipe(take(1), tap(user => this.currentUserDetails = user));
    }

}
