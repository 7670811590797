import {Routes} from '@angular/router';
import {LayoutComponent} from "./_layout/layout/layout.component";
import {AuthGuard} from "./shared/_guards/auth.guard";

export const routes: Routes = [
    {path: '', redirectTo: '/login', pathMatch: 'full'}, // redirect to `login page`
    {
        path: 'login',
        loadChildren: () => import('./_modules/login/login.module').then(mod => mod.LoginModule)
    },
    {
        path: 'forgot-password',
        loadChildren: () => import('./_modules/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('./_modules/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule)
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'deal-desk',
                loadChildren: () => import('./_modules/deal-desk/deal-desk.module').then(mod => mod.DealDeskModule)
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./_modules/dashboard/dashboard.module').then(mod => mod.DashboardModule)
            },
            {
                path: 'contracts',
                loadChildren: () => import('./_modules/contracts/contracts.module').then(mod => mod.ContractsModule)
            },
            {
                path: 'bidding',
                loadChildren: () => import('./_modules/bid-request/bid-request.module').then(mod => mod.BidRequestModule)
            },
        ]
    },
    {
        path: '**',
        loadChildren: () => import('./_modules/page-not-found/page-not-found.module').then(mod => mod.PageNotFoundModule)
    }
];
