import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthenticationService } from "../_services/apis/authentication.service";
import { ZenDialogMsgService } from "../_services/helpers/zen-dialog-msg.service";
import {NavigationService} from "../_services/helpers/navigation.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private zenDialogMsgSvc: ZenDialogMsgService,
        private navSvc: NavigationService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        this.authService.redirectUrl = state.url;
        const token = route.queryParams['token'];

        if (token) {
            // Wait for the loginWithToken observable to resolve
            return this.authService.loginWithToken(token).pipe(
                map(res => {
                    if (res.token) {
                        // Remove query params
                        this.router.navigate(this.navSvc.getDealDeskRoute(), { queryParams: { token: null }, queryParamsHandling: 'merge' });
                        return true;
                    } else {
                        console.log('Failed to log in with token');
                        this.navSvc.navigate(['/']);
                        this.zenDialogMsgSvc.openToast(false, 'Sorry! The link you followed has expired.');
                        return false;
                    }
                }),
                catchError(err => {
                    console.error('Login error:', err);
                    this.navSvc.navigate(['/']);
                    this.zenDialogMsgSvc.openToast(false, 'An error occurred during login.');
                    return of(false);
                })
            );
        } else if (this.authService.getCurrentUser?.token) {
            // User is already logged in
            return true;
        }

        // If no token or user, redirect to login
        this.authService.logout();
        this.navSvc.navigate(['/']);
        return false;
    }
}
