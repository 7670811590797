import {Component} from '@angular/core';
import {ZenIconsEnum} from "../../shared/_enums/zen-icons.enum";
import {BreadcrumbService} from "./breadcrumb.service";
import {MatMenuModule} from "@angular/material/menu";
import {CommonModule} from "@angular/common";

export interface BreadcrumbConfig {
  pageName?: string;
  command?: () => void;
  breadcrumbs?: Breadcrumb[];
  mobileBreadcrumbs?: Breadcrumb[];
}

export interface Breadcrumb {
  icon: ZenIconsEnum;
  title: string;
  currentPage?: boolean; // current active page
  customerId?: number;
  lenId?: string;
  disableClick?: boolean;
  serviceAddressId?: number;
  meterId?: number;
  command?: () => void;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  standalone: true,
  imports: [
      CommonModule,
      MatMenuModule
  ],
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  hovered: boolean;

  constructor(public bcSvc: BreadcrumbService) {}

}
