<!-- When there is page name present -->
<ng-container *ngIf="bcSvc.config?.pageName">
  <div class="zen-breadcrumb-main with-page-name" (click)="bcSvc.handleTitleClick()">
    <div class="zen-breadcrumb-item" [matMenuTriggerFor]="breadcrumbMenu"
         (mouseover)="hovered=bcSvc.config?.breadcrumbs?.length>0" (mouseout)="hovered=false">
      <i class="material-icons">{{hovered ? 'keyboard_arrow_down' : 'keyboard_arrow_left'}}</i>
      <p>{{bcSvc.config?.pageName || '-'}}</p>
    </div>
  </div>

  <mat-menu #breadcrumbMenu="matMenu" class="zen-breadcrumb-main">
    <ng-container *ngFor="let bread of bcSvc.config.breadcrumbs">
      <div mat-menu-item class="zen-breadcrumb-item" [ngClass]="{current: bread.currentPage}"
           (click)="bcSvc.handleBcClick(bread)">
        <i class="material-icons current-icon">{{bread.currentPage ? 'done' : ''}}</i>
        <i class="material-icons">{{bread.icon}}</i>
        <p class="m-0">{{bread.title || '-'}}</p>
      </div>
    </ng-container>
  </mat-menu>
</ng-container>

<!-- Withput page name -->
<ng-container *ngIf="!bcSvc.config?.pageName">
    <div class="zen-breadcrumb-main without-page-name">

        <ng-container *ngFor="let bc of bcSvc.config.breadcrumbs">
            <div class="bc-container" [class.hyperlink]="bc?.command"
                 (click)="bc?.command ? bc?.command() : null">
                <i class="material-icons bc-icon">keyboard_arrow_left</i>
                <p class="text-md bc-title">{{bc.title || '-'}}</p>
            </div>
        </ng-container>

    </div>
</ng-container>
